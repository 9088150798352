import { validLanguages } from "../App";

export class ScrollNavigation {
  init() {
    // Make browser forget last position on the page for better scroll UX
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }

    // Set up listener for change of anchor in URL and scroll to the given element
    window.addEventListener("hashchange", () => {
      this.scrollToElementInHash();
    });
  }

  _handlescrollToElement(name) {
    // if there's a language switch, do no proceed
    if (validLanguages.includes(name)) return;

    const element = document.getElementById(name);
    if (element) {
      element.scrollIntoView();
    }
  }

  scrollToElementInHash() {
    this._handlescrollToElement(window.location.hash.substring(1)); // get hash without # symbol
  }
}
