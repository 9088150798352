import { Carousel } from "bootstrap";
import expandIcon from "../../img/expand-button.svg";
import { getParticipationText } from "../helpers/getParticipationText";

const isMobile = window.innerWidth < 1200;

export class Conferences {
  conferencesItems = [];

  init(photosDirectory, conferences, language) {
    console.log("Rendering conferences", conferences);
    if (!conferences || !conferences.length) {
      return;
    }
    if (isMobile) {
      this._initMobileCarousel(photosDirectory, conferences, language);
    } else {
      this._initDesktopCarousel(photosDirectory, conferences, language);
    }
  }

  _initMobileCarousel(photosDirectory, conferences, language) {
    const conferencesCarousel = document.querySelector("#conferencesCarousel");
    conferencesCarousel.innerHTML = "";
    conferencesCarousel.insertAdjacentHTML(
      "beforeend",
      `
        <div class="mobile-carousel-container">
          <div class="carousel-inner" data-jsSelector="conferenceContainer"></div>
          <div class="nav-buttons">
            <button class="carousel-button carousel-button-prev" type="button" data-bs-target="#conferencesCarousel" data-bs-slide="prev">
              <i class="fas fa-chevron-left"></i>
            </button>
            <button class="carousel-button carousel-button-next" type="button" data-bs-target="#conferencesCarousel" data-bs-slide="next">
              <i class="fas fa-chevron-right"></i>
            </button>    
          </div>      
        </div>
      `,
    );
    // bootstrap carousel needs to be initialized in this way in order to allow touch controls
    new Carousel(conferencesCarousel, {
      interval: false,
      wrap: true,
      touch: true,
    });

    this._renderConferences(photosDirectory, conferences, language);
    this._initExpandButtons();
  }

  _initDesktopCarousel(photosDirectory, conferences, language) {
    const conferencesCarousel = document.querySelector("#conferencesCarousel");
    conferencesCarousel.innerHTML = "";
    conferencesCarousel.insertAdjacentHTML(
      "beforeend",
      `
      <div class="desktop-carousel-container">
        <button class="carousel-button carousel-button-prev" type="button" data-bs-target="#conferencesCarousel" data-bs-slide="prev">
          <i class="fas fa-chevron-left"></i>
        </button>
        <div class="carousel-inner" data-jsSelector="conferenceContainer"></div>
        <button class="carousel-button carousel-button-next" type="button" data-bs-target="#conferencesCarousel" data-bs-slide="next">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    `,
    );
    // bootstrap carousel needs to be initialized in this way in order to allow touch controls
    new Carousel(conferencesCarousel, {
      interval: false,
      wrap: true,
      touch: true,
    });
    this._renderConferences(photosDirectory, conferences, language);
    this._equalizeHeights();
    window.addEventListener("resize", this._equalizeHeights.bind(this));
  }

  _renderConferences(photosDirectory, conferences, language) {
    const conferencesContainer = document.querySelector(
      '[data-jsSelector="conferenceContainer"]',
    );
    conferencesContainer.innerHTML = "";
    for (let i = 0; i < conferences.length; i++) {
      const carouselItem = document.createElement("div");
      carouselItem.classList.add("carousel-item");
      if (i === 0) {
        carouselItem.classList.add("active");
      }
      const participationText = getParticipationText(language);
      const conferenceItem = this._renderConferenceContent(
        photosDirectory,
        conferences[i],
        language,
        participationText,
      );

      carouselItem.insertAdjacentHTML("beforeend", conferenceItem);
      conferencesContainer.appendChild(carouselItem);
      this.conferencesItems.push(
        carouselItem.querySelector(".conference-content"),
      );
    }
  }

  _renderConferenceContent(
    photosDirectory,
    conference,
    language,
    participationText,
  ) {
    const image = `${photosDirectory}/${conference.img}`;
    const imgHtml = `
      <img src="${image}" alt="${conference[`title_${language}`]} image" class="conference-img" />
    `;

    const infoTable = `
      <div class="info-table">
        <div class="table-cell">${conference[`date_${language}`]}</div>
        <div class="divider"></div>
        <div class="table-cell">${conference["place"]}</div>
      </div>
  `;

    const detailsHtml = `
     <p>${conference[`short_description_${language}`]}</p>
     <h3>${participationText}</h3>
     <p>${conference[`details_${language}`]}</p>
  `;

    if (isMobile) {
      return `
      <div class="conference-content">
        <div class="mobile-container">
          <h2>${conference[`title_${language}`]}</h2>
          ${infoTable}
          ${imgHtml}
          ${detailsHtml}
          <div class="grey-out"></div>
          <button class="expand-btn">
            <img src="${expandIcon}" alt="Expand/Collapse" class="button-img">
          </button>
        </div>
      </div>
    `;
    } else {
      return `
      <div class="conference-content">
        <h2>${conference[`title_${language}`]}</h2>
        <div class="desktop-container">
          <div class="split-left">${imgHtml}</div>
          <div class="split-right">
            ${infoTable}
            ${detailsHtml}
          </div>
        </div>
      </div>
    `;
    }
  }

  _initExpandButtons() {
    const expandButtons = document.querySelectorAll(".expand-btn");
    const allMobileContainers = document.querySelectorAll(".mobile-container");
    allMobileContainers.forEach((container) => {
      container.style.maxHeight = "500px";
    });

    expandButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const mobileContainer = button.closest(".mobile-container");
        const isCollapsed = mobileContainer.style.maxHeight === "none";
        allMobileContainers.forEach((content) => {
          const greyOut = content.querySelector(".grey-out");
          if (isCollapsed) {
            content.style.maxHeight = "540px";
            greyOut.style.opacity = "1";
          } else {
            content.style.maxHeight = "none";
            greyOut.style.opacity = "0";
          }
        });
        if (isCollapsed) {
          expandButtons.forEach((btn) => btn.classList.remove("open"));
          expandButtons.forEach((btn) => {
            btn.style.position = "absolute";
          });
        } else {
          expandButtons.forEach((btn) => btn.classList.add("open"));
          expandButtons.forEach((btn) => {
            btn.style.position = "relative";
          });
        }
      });
    });
  }

  _equalizeHeights() {
    const maxHeight = this.conferencesItems.reduce((max, item) => {
      return item.offsetHeight > max ? item.offsetHeight : max;
    }, 0);
    this.conferencesItems.forEach((item) => {
      item.style.height = `${maxHeight}px`;
    });
  }
}
