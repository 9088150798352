const nav = document.querySelector("nav.navbar"),
  toggler = nav.querySelector(".navbar-toggler"),
  burger = toggler.querySelector(".burger"),
  mobLinks = nav.querySelectorAll(".navbar-collapse a"),
  body = document.querySelector("body");
const toggleMobileMenu = () => {
  if (window.innerWidth < 768) {
    body.classList.toggle("no-scroll");
  }
  nav.classList.toggle("mobile-menu-opened");
  burger.classList.toggle("cross");
};

if (burger) {
  burger.addEventListener("click", () => {
    toggleMobileMenu();
  });
}

if (mobLinks) {
  mobLinks.forEach((link) => {
    link.addEventListener("click", () => {
      burger.click();
    });
  });
}
