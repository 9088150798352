import { translations } from "../constants/translations";
import { Carousel } from "bootstrap";

export class Projects {
  projectsListContainer = document.createElement("div");
  projectsList = document.createElement("div");
  projectsDetailContainer = document.createElement("div");
  projectsNavs = document.createElement("div");
  projectsContainer = document.querySelector(
    '[data-jsSelector="projectsContainer"]',
  );

  init(projects, language) {
    console.log("Rendering projects", projects);
    if (!projects) {
      return;
    }
    if (window.innerWidth >= 768) {
      this._initDesktopProjects();
      if (this.projectsList) {
        this.projectsList.innerHTML = "";
        for (let i = 0; i < projects.length; i++) {
          const item = this._renderProjectListItem(
            i + 1,
            projects[i],
            language,
          );
          this.projectsList.insertAdjacentHTML("beforeend", item);
        }
      }

      if (this.projectsDetailContainer) {
        this.projectsDetailContainer.innerHTML = "";
        for (let i = 0; i < projects.length; i++) {
          const item = this._renderDesktopProjectDetail(
            i + 1,
            projects[i],
            language,
          );
          this.projectsDetailContainer.insertAdjacentHTML("beforeend", item);
        }
      }
    } else if (this.projectsList) {
      console.log("Initializing mobile carousel");
      this._initMobileCarousel(projects, language);
    }
  }
  _initDesktopProjects() {
    this.projectsContainer.insertAdjacentElement(
      "beforeend",
      this.projectsListContainer,
    );
    this.projectsListContainer.insertAdjacentElement(
      "beforeend",
      this.projectsList,
    );

    this.projectsListContainer.insertAdjacentElement(
      "beforeend",
      this.projectsNavs,
    );
    this.projectsContainer.insertAdjacentElement(
      "beforeend",
      this.projectsDetailContainer,
    );

    this.projectsListContainer.classList.add("projects-list");
    this.projectsList.classList.add("list-group");
    this.projectsNavs.classList.add("projects-nav");
    this.projectsDetailContainer.classList.add(
      "projects-detail",
      "tab-content",
    );
  }

  _renderProjectListItem(key, project, language) {
    const index = key < 10 ? `0${key}` : key;
    const activeClass = key === 1 ? "active" : "";
    return `<a class="list-group-item list-group-item-action ${activeClass}" id="list-project${key}"
               data-bs-toggle="list" href="#project${key}" role="tab">
              <div class="index">${index}</div>
              <div class="content">
                <h2>${project[`name_${language}`]}</h2>
                <h4>${project[`subtitle_${language}`]}</h4>
              </div>
            </a>`;
  }

  _renderDesktopProjectDetail(key, project, language) {
    const webLanguage = language === "cs" ? "" : "en";
    const link = project["website"]
      ? `<a href="${
          project["website"]
        }/${webLanguage}" target="_blank" class="btn btn-outline-white">${
          translations[`see_more_${language}`]
        }</a>`
      : "";
    const activeClass = key === 1 ? "show active" : "";
    return `<div id="project${key}" class="tab-pane fade ${activeClass}" role="tabpanel" aria-labelledby="list-project${key}">
              <h2>${project[`name_${language}`]}</h2>
              <p>${project[`desc_${language}`]}</p>
              ${link}
            </div>`;
  }

  _initMobileCarousel(projects, language) {
    const carouselElement = document.createElement(`div`);
    carouselElement.classList.add("carousel", "slide");
    carouselElement.id = "projectsCarousel";
    const carousel = new Carousel(carouselElement, {
      interval: false,
      wrap: true,
      touch: true,
    });
    this.projectsContainer.insertAdjacentElement("beforeend", carouselElement);

    const carouselNav = document.createElement(`div`);
    carouselNav.classList.add("carousel-button");
    const prevButton = `
            <button class="carousel-button carousel-button-prev" type="button" data-bs-target="#projectsCarousel" data-bs-slide="prev">
                <i class="fas fa-chevron-left"></i>
            </button>`;
    carouselNav.insertAdjacentHTML("beforeend", prevButton);
    const currentProject = document.createElement(`div`);
    currentProject.classList.add("current-project");
    const firstProjectContent = this._renderCurrentProject(
      1,
      projects[0],
      language,
    );
    currentProject.insertAdjacentHTML("beforeend", firstProjectContent);
    carouselNav.insertAdjacentElement("beforeend", currentProject);
    const nextButton = `<button class="carousel-button carousel-button-next" type="button" data-bs-target="#projectsCarousel" data-bs-slide="next">
                <i class="fas fa-chevron-right"></i>
            </button>`;
    carouselNav.insertAdjacentHTML("beforeend", nextButton);
    carouselElement.insertAdjacentElement("beforeend", carouselNav);

    const carouselInner = document.createElement(`div`);
    carouselInner.classList.add("carousel-inner");
    carouselElement.insertAdjacentElement("beforeend", carouselInner);
    for (let i = 0; i < projects.length; i++) {
      const carouselItem = document.createElement(`div`);
      carouselItem.classList.add("carousel-item");
      if (i === 0) {
        carouselItem.classList.add("active");
      }
      const projectDetail = this._renderCarouselItem(
        i + 1,
        projects[i],
        language,
      );
      carouselItem.insertAdjacentHTML("beforeend", projectDetail);
      carouselInner.insertAdjacentElement("beforeend", carouselItem);
      this._setFixedHeight();
    }

    carouselElement.addEventListener("slide.bs.carousel", (event) => {
      currentProject.innerHTML = "";
      const i = event.to;
      const currentProjectContent = this._renderCurrentProject(
        i + 1,
        projects[i],
        language,
      );
      currentProject.insertAdjacentHTML("beforeend", currentProjectContent);
    });
  }

  _renderCarouselItem(key, project, language) {
    const webLanguage = language === "cs" ? "" : "en";
    const link = project["website"]
      ? `<a href="${
          project["website"]
        }/${webLanguage}" target="_blank" class="btn btn-outline-white">${
          translations[`see_more_${language}`]
        }</a>`
      : "";
    return `<div id="project${key}" class="carousel-item-content">
              <h2>${project[`name_${language}`]}</h2>
              <p>${project[`desc_${language}`]}</p>
              ${link}
            </div>`;
  }

  _renderCurrentProject(key, project, language) {
    const index = key < 10 ? `0${key}` : key;
    return `<div class="current-project-content" id="current-project${key}">
              <div class="index">${index}</div>
              <div class="content">
                <h2>${project[`name_${language}`]}</h2>
                <h4>${project[`subtitle_${language}`]}</h4>
              </div>
            </div`;
  }

  _setFixedHeight() {
    let maxHeight = 0;
    const slides = document.querySelectorAll("div.carousel-item");
    slides.forEach(function (slide) {
      slide.style.display = "block";
      const slideHeight = slide.offsetHeight;
      slide.style.display = "";
      maxHeight = slideHeight > maxHeight ? slideHeight : maxHeight;
    });
    document.querySelector("div.carousel-inner").style.height =
      maxHeight + "px";
  }
}
