export class Console {
  productionHostname = "mild.blue";

  override() {
    const PROD = window.location.hostname === this.productionHostname;
    const _log = console.log;
    const _error = console.error;
    const _warning = console.warning;

    console.error = function (errMessage) {
      _error.apply(this, arguments);
    };

    console.log = function (logMessage) {
      if (!PROD) {
        _log.apply(this, arguments);
      }
    };

    console.warning = function (warnMessage) {
      _warning.apply(this, arguments);
    };
  }
}
