import { translations } from "../constants/translations";

export class Company {
  // Email
  emailContainer = document.querySelector('[data-jsSelector="company-email"]');

  // Hiring
  hiringContainer = document.querySelector(
    '[data-jsSelector="company-hiring"]',
  );

  // Company info
  companyInfoContainer = document.querySelector(
    '[data-jsSelector="company-info"]',
  );

  // Company mailing address
  companyMailContainer = document.querySelector(
    '[data-jsSelector="company-mail"]',
  );

  // Company phone number & email
  companyPhoneContainer = document.querySelector(
    '[data-jsSelector="company-phone"]',
  );
  companyEmailContainer = document.querySelector(
    '[data-jsSelector="company-email-solo"]',
  );

  // Contact person
  contactPersonContainer = document.querySelector(
    '[data-jsSelector="company-contact-person"]',
  );

  // Social
  socialContainers = document.querySelectorAll('[data-jsSelector="social"]');

  // Texts
  mottoContainer = document.querySelector('[data-jsSelector="motto"]');
  submottoContainer = document.querySelector('[data-jsSelector="submotto"]');
  howWeWorkContainer = document.querySelector(
    '[data-jsSelector="about-how-we-work"]',
  );
  whatWeBelieveContainer = document.querySelector(
    '[data-jsSelector="about-what-we-believe"]',
  );

  init(company, language) {
    // Email
    const companyEmail = company["email"];
    if (companyEmail && this.emailContainer) {
      this._renderEmail(companyEmail, language);
    }

    // Hiring
    if (company["hiring"] && this.hiringContainer) {
      this._renderHiring(company["hiring"], language);
    }

    // Company info
    if (this.companyInfoContainer) {
      this._renderCompanyInfo(company);
    }

    // Company mailing address
    if (this.companyMailContainer) {
      this._renderCompanyMail(company, language);
    }

    // Contact person
    if (this.contactPersonContainer) {
      this._renderContactPerson(company, companyEmail);
    }

    // Company email & phone
    if (this.companyEmailContainer && this.companyPhoneContainer) {
      this._renderCompanyContact(company);
    }

    // Social
    if (company["social"] && this.socialContainers.length) {
      this._renderSocial(company["social"]);
    }

    // Texts
    if (company[`slogan_short_${language}`] && this.mottoContainer) {
      this._renderMotto(company[`slogan_short_${language}`]);
    }

    if (company[`motto_${language}`] && this.submottoContainer) {
      this._renderSubmotto(company[`motto_${language}`]);
    }

    if (company[`how_we_work_${language}`] && this.howWeWorkContainer) {
      this._renderHowWeWork(company[`how_we_work_${language}`]);
    }

    if (company[`what_we_believe_${language}`] && this.whatWeBelieveContainer) {
      this._renderWhatWeBelive(company[`what_we_believe_${language}`]);
    }
  }

  _renderEmail(email, language) {
    this.emailContainer.innerHTML = `<a href="mailto:${email}?subject=Contact from Mild Blue website">
                              <h2>${
                                translations[`contact_us_${language}`]
                              }</br>${
                                translations[`contact_us_email_${language}`]
                              }</h2>
                              <span>${email}</span>
                           </a>`;
  }

  _renderHiring(hiring, language) {
    this.hiringContainer.innerHTML = `<a href="${hiring}" target="_blank">
                          <h2>${translations[`hiring_title_${language}`]}</h2>
                          <span>${
                            translations[`hiring_subtitle_${language}`]
                          }</span>
                      </a>`;
  }

  _renderCompanyInfo(company) {
    const name = company["name"];
    const address = company["address"];
    const ico = company["ico"];
    const dic = company["dic"];

    this.companyInfoContainer.innerHTML = "";

    if (name) {
      const template = `<strong>${name}</strong>`;
      this.companyInfoContainer.insertAdjacentHTML("beforeend", template);
    }

    if (address && address.length) {
      const template = `<br>${address.join("<br>")}</br>`;
      this.companyInfoContainer.insertAdjacentHTML("beforeend", template);
    }

    if (ico) {
      const template = `<br><br>IČ: ${ico}`;
      this.companyInfoContainer.insertAdjacentHTML("beforeend", template);
    }

    if (dic) {
      const template = `<br>DIČ: ${dic}`;
      this.companyInfoContainer.insertAdjacentHTML("beforeend", template);
    }
  }

  _renderCompanyMail(company, language) {
    const name = company["name"];
    const address = company["mailing_address"];
    const google_map = company["google_map"];
    this.companyMailContainer.innerHTML = "";

    if (name) {
      const template = `<strong>${name}</strong>`;
      this.companyMailContainer.insertAdjacentHTML("beforeend", template);
    }

    if (address && address.length) {
      const template = `<br>${address.join("<br>")}</br>`;
      this.companyMailContainer.insertAdjacentHTML("beforeend", template);
    }

    if (google_map) {
      const icon = `<br><br><i class="fas fa-${google_map.icon}"></i>`;
      this.companyMailContainer.insertAdjacentHTML(
        "beforeend",
        `${icon}<a href="${google_map.url}" target="_blank"/>${
          translations[`google_map_${language}`]
        }</br>`,
      );
    }
  }

  _renderContactPerson(company, companyEmail) {
    const name = company["contact_person"];
    const phone = company["contact_person_phone_number"];
    const email = company["contact_person_email"];

    this.contactPersonContainer.innerHTML = "";

    if (name) {
      const person = `<strong>${name}</strong>`;
      this.contactPersonContainer.insertAdjacentHTML("beforeend", person);
    }

    if (phone) {
      const number = `<a href="tel:${phone}">${this._formatPhoneNumber(
        phone,
      )}</a>`;
      this.contactPersonContainer.insertAdjacentHTML("beforeend", number);
    }

    if (email) {
      const emailTemplate = `<br><a href="mailto:${email}?subject=Contact from Mild Blue website">${email}</a>`;
      this.contactPersonContainer.insertAdjacentHTML(
        "beforeend",
        emailTemplate,
      );
    }
  }

  _renderCompanyContact(company) {
    const phone = company["contact_person_phone_number"];
    const email = company["email"];

    this.companyEmailContainer.innerHTML = "";
    this.companyPhoneContainer.innerHTML = "";

    if (email) {
      const emailTemplate = `<a href="mailto:${email}?subject=Contact from Mild Blue website">${email}</a>`;
      this.companyEmailContainer.insertAdjacentHTML("beforeend", emailTemplate);
    }

    if (phone) {
      const number = `<a href="tel:${phone}">${this._formatPhoneNumber(
        phone,
      )}</a>`;
      this.companyPhoneContainer.insertAdjacentHTML("beforeend", number);
    }
  }

  _renderSocial(links) {
    for (const container of this.socialContainers) {
      container.innerHTML = "";
      for (const link of links) {
        const iconTemplate =
          link.icon && link.icon !== "cb"
            ? `<i class="fab fa-${link.icon}"></i>`
            : "";
        const linkTemplate = `<a href="${link.url}" class="social-${link.icon}" target="_blank">${iconTemplate}</a>`;
        container.insertAdjacentHTML("beforeend", linkTemplate);
      }
    }
  }

  _renderMotto(content) {
    const replacementCs = ["zachraňují životy"];
    const replacementEn = ["save lives"];
    const replacementDe = ["Leben retten"];
    const pattern = [...replacementCs, ...replacementEn, ...replacementDe].join(
      "|",
    );

    content = content.replace(new RegExp(pattern, "g"), "<span>$&</span>");

    this.mottoContainer.innerHTML = content;
  }

  _renderSubmotto(content) {
    const replacementCs = [
      "algoritmů umělé inteligence",
      "užitečných digitálních řešení",
    ];
    const replacementEn = [
      "artificial intelligence algorithms",
      "useful digital solutions",
    ];
    const replacementDe = [
      "nützlicher digitaler Lösungen",
      "künstlichen Intelligenz-Algorithmen",
    ];
    const pattern = [...replacementCs, ...replacementEn, ...replacementDe].join(
      "|",
    );

    content = content.replace(new RegExp(pattern, "g"), "<span>$&</span>");
    this.submottoContainer.innerHTML = content.replace(
      "Mild Blue",
      `<span>Mild Blue</span>`,
    );
  }

  _renderHowWeWork(content) {
    const sentences = content.split(".");
    const first = `<h2>${sentences[0]}.</h2>`;
    const rest = sentences.splice(1);

    this.howWeWorkContainer.innerHTML = `${first}${rest.join(". ")}`;
  }

  _renderWhatWeBelive(content) {
    const sentences = content.split(".");
    const first = `<h2>${sentences[0]}.</h2>`;
    const rest = sentences.splice(1);

    this.whatWeBelieveContainer.innerHTML = `${first}${rest.join(". ")}`;
  }

  _formatPhoneNumber(phoneNumberString) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(420|)?(\d{3})(\d{3})(\d{3})$/);
    if (match) {
      const intlCode = match[1] ? "+420 " : "";
      return `${intlCode} ${match[2]} ${match[3]} ${match[4]}`;
    }
    return "";
  }
}
