export const getParticipationText = (language) => {
  switch (language) {
    case "en":
      return "How did the Mild Blue team participate?";
    case "de":
      return "Wie hat das Mild Blue Team teilgenommen?";
    default:
      return "Jak se zapojil tým Mild Blue?";
  }
};
