import { Carousel } from "bootstrap";

export class Links {
  linksContainer = document.querySelector('[data-jsSelector="linksContainer"]');
  linksItems = [];

  init(imagesDirectory, links, language) {
    console.log("Rendering links", links);

    if (links.length) {
      this._renderLinks(imagesDirectory, links, language);
      this._initCarousel();
    }
  }

  _renderLinks(imagesDirectory, links, language) {
    this.linksContainer.innerHTML = "";
    for (let i = 0; i < links.length; i++) {
      const carouselItem = document.createElement("div");
      carouselItem.classList.add("carousel-item");
      if (i === 0) {
        carouselItem.classList.add("active");
      }
      const linksItem = this._renderLink(imagesDirectory, links[i], language);
      carouselItem.insertAdjacentHTML("beforeend", linksItem);
      this.linksContainer.appendChild(carouselItem);
      this.linksItems.push(linksItem);
    }
  }

  _renderLink(imagesDirectory, link, language) {
    // todo: use language
    return `<a href="${link.url}" target="_blank" class="links-item">
              <i class="fas fa-external-link-alt"></i>
              <div class="image">
                <div class="image-inner">
                  <img src="${imagesDirectory}/${link.img}" alt="${
                    link.title
                  }"/>
                </div>
              </div>
              <div class="content">
                <div>
                  <h4>${link.source}</h4>
                  <h3>${link.title}</h3>
                </div>
                <div class="tags">
                    ${link.tags.map((tag) => `<span>${tag}</span>`).join("")}
                </div>
              </div>
            </a>`;
  }

  _initCarousel() {
    const linksCarousel = document.querySelector("#linksCarousel");
    const carousel = new Carousel(linksCarousel, {
      interval: false,
      wrap: true,
      touch: true,
    });
    let carouselItems = this.linksContainer.querySelectorAll(
      ".carousel .carousel-item",
    );
    let linksItems = this.linksContainer.querySelectorAll(".links-item");
    const maxHeight = Math.max(
      ...[...linksItems].map((item) => {
        let itemHeight = item.offsetHeight;
        const h3 = item.querySelector("h3");
        if (h3) {
          itemHeight += h3.offsetHeight;
        }
        return itemHeight;
      }),
    );

    let linksPerSlide;
    if (window.innerWidth < 768) {
      linksPerSlide = 1;
    } else {
      linksPerSlide = 2;
    }
    linksItems.forEach((el) => {
      if (linksPerSlide === 2) {
        el.style.height = `${maxHeight + 40}px`;
      } else {
        el.style.height = `${maxHeight - 40}px`;
      }
    });

    carouselItems.forEach((el) => {
      if (linksPerSlide === 2) {
        let next = el.nextElementSibling;
        if (!next) {
          next = carouselItems[0];
        }
        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
      }
    });
  }
}
