import { ContentService } from "../services/ContentService";

export class Team {
  contentService = new ContentService();
  teamContainer = document.querySelector('[data-jsSelector="teamContainer"]');

  init(photosDirectory, members, companyEmail, language) {
    console.log("Rendering team", members);
    if (!members) {
      return;
    }

    // Render team
    this.teamContainer.innerHTML = "";
    const row = document.createElement("div");
    row.classList.add("row");
    for (const m of members) {
      row.insertAdjacentHTML(
        "beforeend",
        this._renderMember(photosDirectory, m, companyEmail, language),
      );
    }
    this.teamContainer.appendChild(row);
  }

  _getTitle(value, language) {
    if (language === "en") {
      value = value.replace("Bc. ", "");
      value = value.replace("Ing. ", "");
      value = value.replace("Mgr. ", "");
      value = value.replace("BcA. ", "");
      value = value.replace(", Dis.", "");

      if (value.includes("MUDr.")) {
        value = value.replace("MUDr. ", "");
        value = `${value}, M.D.`;
      }

      return value;
    }

    return value;
  }

  _renderMember(photosDirectory, member, companyEmail, language) {
    const image = `${photosDirectory}/${member.key}.webp`;
    return `<div class="col-sm-6 col-md-4 col-lg-3" data-aos="fade-up">
              <div class="team-members__item">
                <div class="header">
                  <button class="modal-button" type="button" data-bs-toggle="modal" data-bs-target="#${
                    member.key
                  }">
                    <div class="item-image">
                      <img src="${image}" alt="${member.name}"/>
                    </div>
                    <div>
                      <h2 class="modal-name">${this._getTitle(
                        member.name,
                        language,
                      )}</h2>
                      <h4 class="modal-title">${
                        member[`title_${language}`] ?? ""
                      }</h4>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <!-- Modal -->
           <div class="modal fade" id="${
             member.key
           }"  tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark"></i>
                            </button>
                            <div class="modal-content">
                                <div class="modal-left">
                                <div class="modal-img-item>">
                                    <img class="modal-img" src="${image}" alt="${
                                      member.name
                                    }"/>
                                </div>
                                    <h2 class="modal-name"> ${this._getTitle(
                                      member.name,
                                      language,
                                    )}</h2>
                                    <h4 class="modal-role">${
                                      member[`title_${language}`] ?? ""
                                    }</h4>
                                </div>
                                <h5 class="short-desc">${
                                  member[`short_desc_${language}`] ?? ""
                                }</h5>
                            </div>
                        </div>
                    </div>
                </div>
           </div>`;
  }
}
