// Bootstrap
import "bootstrap";

// Font Awesome
import "@fortawesome/fontawesome-free/js/all";

// Custom SCSS
import "../scss/index.scss";

// Custom JS - Interaction
import "./modules/navbar";
import "./modules/phone-sticker";

// Custom JS - Rendering
import { Console } from "./services/Console";
import { App } from "./App";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  once: true,
  duration: 1500,
});

new Console().override();
new App().init();
