const phoneSticker = document.querySelector(
  '[data-jsSelector="phone-sticker"]',
);
const phoneStickerToggle = document.querySelector(
  '[data-jsSelector="phone-sticker-toggle"]',
);

if (phoneSticker && phoneStickerToggle) {
  phoneSticker.addEventListener("click", () => {
    phoneSticker.classList.toggle("open");
  });

  phoneSticker.addEventListener("mouseenter", () => {
    phoneSticker.classList.add("open");
  });

  phoneSticker.addEventListener("mouseleave", () => {
    phoneSticker.classList.remove("open");
  });
}
